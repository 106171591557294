exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookiePolicy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-matteo-gioioso-jsx": () => import("./../../../src/pages/matteo-gioioso.jsx" /* webpackChunkName: "component---src-pages-matteo-gioioso-jsx" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-projects-works-js": () => import("./../../../src/pages/projects-works.js" /* webpackChunkName: "component---src-pages-projects-works-js" */),
  "component---src-pages-what-matteo-can-do-jsx": () => import("./../../../src/pages/what-matteo-can-do.jsx" /* webpackChunkName: "component---src-pages-what-matteo-can-do-jsx" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

